@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Forma DJR Regular";
  src: url("./fonts/FormaDJRBanner-Regular-Edu.otf");
}

@font-face {
  font-family: "Forma DJR Medium Italic";
  src: url("./fonts/FormaDJRBanner-MediumItalic-Edu.otf");
}

@font-face {
  font-family: "Forma DJR Extra Bold";
  src: url("./fonts/FormaDJRBanner-ExtraBold-Edu.otf");
}

@font-face {
  font-family: "Forma DJR Italic";
  src: url("./fonts/FormaDJRBanner-Italic-Edu.otf");
}

@font-face {
  font-family: "Forma DJR Bold";
  src: url("./fonts/FormaDJRBanner-Bold-Edu.otf");
}

html {
  background-color: black;
}

body {
  background-color: black;
  color: white;
  letter-spacing: 0.5px;
}
