:root {
  /* --ticket-bg-color: white; */
  --ticket-bg-color: rgba(255, 255, 255, 0.15);
  /* --ticket-button-rgb: 214 77 45; */
  /* --ticket-button-rgb: 126 34 206; */
  --ticket-button-rgb: 0 0 0;
}

#tsparticles {
  z-index: -1;
}

#tsparticles::after {
  content: "";
  /* position: fixed;
  inset: 0 0 0 0;
  width: 100%;
  height: 100%;
  background-repeat: repeat;
  background-position: center center; */
  /* background-image: url(https://grainy-gradients.vercel.app/noise.svg); */
  height: 300%;
  width: 300%;
  position: fixed;
  opacity: 0.05;
  background-image: url("https://upload.wikimedia.org/wikipedia/commons/7/76/1k_Dissolve_Noise_Texture.png");
  animation: animateGrain 8s steps(10) infinite;
  filter: contrast(150%) brightness(0.5);
  pointer-events: none;
}

@keyframes animateGrain {
  0%,
  100% {
    transform: translate(0, 0);
  }
  10% {
    transform: translate(-5%, -10%);
  }
  20% {
    transform: translate(-15%, -20%);
  }
  30% {
    transform: translate(-5%, -10%);
  }
  40% {
    transform: translate(-15%, -20%);
  }

  50% {
    transform: translate(-5%, -10%);
  }
  60% {
    transform: translate(-15%, -20%);
  }
  70% {
    transform: translate(-5%, -10%);
  }
  80% {
    transform: translate(-15%, -20%);
  }
  90% {
    transform: translate(-5%, -10%);
  }
  100% {
    transform: translate(-15%, -20%);
  }
}

#tsparticles canvas {
  filter: blur(80px) saturate(175%);
  z-index: -1;
}

.ticket-wrap {
  filter: drop-shadow(0px 8px 6px rgba(0, 0, 0, 0.25));
}

.ticket {
  width: 100%;
  position: relative;
}

.ticket--top {
  border-radius: 0.75rem 0.75rem 0 0;
  background: radial-gradient(circle 12px at bottom left, #0000 98%, var(--ticket-bg-color)) bottom left,
    radial-gradient(circle 12px at bottom right, #0000 98%, var(--ticket-bg-color)) bottom right;
  background-size: 50% 100%;
  background-repeat: no-repeat;
}

.ticket--top::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 12px;
  right: 12px;
  height: 1px;
  border: 1px dashed rgba(0, 0, 0, 0.2);
}

.ticket--bottom {
  border-radius: 0 0 0.75rem 0.75rem;
  background: radial-gradient(circle 12px at top left, #0000 98%, var(--ticket-bg-color)) bottom left,
    radial-gradient(circle 12px at top right, #0000 98%, var(--ticket-bg-color)) bottom right;
  background-size: 50% 100%;
  background-repeat: no-repeat;
}

.ticket-button {
  background-color: rgba(0, 0, 0, 0.3);
  box-shadow: 0px 17px 10px -12px rgb(var(--ticket-button-rgb) / 30%);
  transition: 0.15s ease-in-out all;
}

.ticket-button:hover {
  transform: translateY(-1px);
  background-color: rgba(0, 0, 0, 0.5);
  box-shadow: 0px 19px 14px -12px rgb(var(--ticket-button-rgb) / 40%);
}

.ticket-button:focus {
  transform: translateY(1px);
  background-color: rgba(0, 0, 0, 0.4);
  box-shadow: 0px 17px 6px -12px rgb(var(--ticket-button-rgb) / 30%);
}

.animate-button:hover span {
  animation: buttonAnimation 0.5s ease forwards;
  animation-delay: var(--animation-delay);
}

@keyframes buttonAnimation {
  0% {
    transform: translateZ(0);
  }

  50% {
    transform: translate3d(0, -225%, 0);
  }
  50.1% {
    transform: translate3d(0, 225%, 0);
  }
  100% {
    transform: translateZ(0);
  }
}
